.URLField {
    display: flex;
    padding-top: 16px;
    padding-bottom: 8px;
}

.URLField_field {
    width: 100%;    
}

.URLField_value {
    min-height: 18px;
    width: 100%;    
}

.SelectField {
    display: flex;
    padding-top: 16px;
    padding-bottom: 8px;
}

.SelectField_field {
    width: 100%;    
}

.SelectField_value {
    min-height: 18px;
    width: 100%;    
}

.LookupField {
    display: flex;
    padding-top: 16px;
    padding-bottom: 8px;
}

.LookupField_field {
    width: 100%;    
}

.LookupField_value {
    min-height: 18px;
    width: 100%;    
}

.DictionaryField {
    overflow: auto;
    max-height: 300px;
    width: 100%;
}

.DictionaryField_key {
    padding: 6px;
    padding-right: 12px;
}

.DictionaryField_key_readonly {
    padding: 6px;
    padding-right: 12px;
    background-color: lightgray;
}

.DictionaryField_value {
    padding: 6px;
}

div.pi-form {
    padding: 24px;
}

div.pi-form div.pi-form_row {
    display: flex;
}

div.FormActions {
    padding-top: 24px;
}

.pi-page {
    box-sizing: border-box;
    /* 
    max-width: calc(100vw);
    max-height: calc(100vh - 64px);
    overflow: auto; 
    */
    padding-top: 6px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
} 

.pi-page .DataGrid {
    max-height: calc(100vh - 18px - 64px);
    overflow: auto;
}

@media screen and (min-width: 600px) {
    /* >600 -> 24px padding */
    .pi-page {
        padding: 24px;
    }
    .pi-page .DataGrid {
        max-height: calc(100vh - 48px - 64px);
    }    
}

@media screen and (min-width: 960px) {

}
* {
    box-sizing: border-box;
  }

.pi-calendar {
    font-family: 'Roboto', sans-serif;  
    position: relative;
    width: 100%; 
    overflow: hidden;
    /* change based on size */
    margin-top: 0px;
    height: calc(100% - 12px);
}

.pi-calendar_days {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction:row;
    overflow-y: hidden;
    overflow-x: auto;

    /* border: 1px solid green; */
}

.pi-calendar_day_card {
    padding: 6px;
    background-color: white;
    min-width: 236px;
    margin: 6px;
    overflow: hidden;

    /*border: 1px solid red;*/
}

.pi-calendar_day {
    /*
    display: flex;
    flex-direction: column;
    flex-flow: column;
    */
    /*
    overflow-x: none;
    */
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: white;
}

.pi-calendar_day_empty {
    width: 30px;
    height: 100%;
    position: relative;
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#cccccc));
    background: linear-gradient(#ffffff, #cccccc);
    border: 1px solid #cccccc;
    color: #777777;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
    -webkit-text-orientation: sideways;
            text-orientation: sideways;
    -webkit-writing-mode: vertical-lr;
            writing-mode: vertical-lr;
    padding: 4px;
}

.pi-calendar_header {
    width: 100%;
    left:0px;
    top:0px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    position: -webkit-sticky;
    position: sticky;  
    z-index: 999;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    /* center items vertically, in this case */
}

.pi-calendar-vert-spacer {
    position: absolute;
    min-height: 24px;
    width: 100%;
}

.pi-calendar-bottom {
    width: 224px;
    bottom: 12px;
    height: 24px;
    z-index: 1000;
    position: absolute;
    background: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(rgba(255, 255, 255, 0.467)));
    background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0.467));
}


.pi-calendar_slot {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    /*background-color: #eeeeee;*/
    background: -webkit-gradient(linear, left top, left bottom, from(#eeeeee), to(#ffffff));
    background: linear-gradient(#eeeeee, #ffffff);
    color: #777777;
    position: absolute;
    width: 100%;
    padding: 5px;
    font-size: 8pt;
}

.pi-calendar_lunch {
    border: none;
    color: black;
    position: absolute;
    width: 100%;
    padding: 5px;
    font-size: 12pt;
    text-align: center;
    vertical-align: center;
    /*background-color: #aaaaaaaa;*/
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(204, 204, 204, 0.467)), color-stop(rgba(238, 238, 238, 0.8)), to(rgba(204, 204, 204, 0.467)));
    background: linear-gradient(rgba(204, 204, 204, 0.467), rgba(238, 238, 238, 0.8), rgba(204, 204, 204, 0.467));
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    /* center items vertically, in this case */
}

.pi-calendar_open_cell {
    width: 100%;
    position: absolute;
}

.pi-calendar_open_cell_selected {
    width: 100%;
    position: absolute;
}

.pi-calendar_open {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    padding: 6px;

    font-size: 12pt;
    text-align: center;
    vertical-align: center;
    border-radius: 5px;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-left: 60px;
    -webkit-transition-duration: .5s;
            transition-duration: .5s;

    text-overflow: ellipsis;
}

/** blue **/
.pi-calendar_slot_blue {
    /* unselected */
    border: 1px solid #2196f3;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(33, 150, 243, 0.667)));
    background: linear-gradient(#ffffff, rgba(33, 150, 243, 0.667));
    color: #2196f3;    
}

.pi-calendar_open_cell_selected .pi-calendar_slot_blue {
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(255, 119, 0, 0.667)));
    background: linear-gradient(#ffffff, rgba(255, 119, 0, 0.667));
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    border: 2px solid #ff7700;
    color: #ff7700;

    -webkit-transition-duration: .5s;

            transition-duration: .5s;   
}

/** green **/
.pi-calendar_slot_green {
    border: 1px solid #21c396;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(33, 195, 150, 0.667)));
    background: linear-gradient(#ffffff, rgba(33, 195, 150, 0.667));
    color: #21c396;    
}

/** red **/
.pi-calendar_slot_red {
    border: 1px solid #c30044;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(195, 0, 68, 0.667)));
    background: linear-gradient(#ffffff, rgba(195, 0, 68, 0.667));
    color: #c30044;    
}

/** gray **/
.pi-calendar_slot_gray {
    border: 1px solid #444444;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(68, 68, 68, 0.667)));
    background: linear-gradient(#ffffff, rgba(68, 68, 68, 0.667));
    color: #444444;    
}

.pi-calendar_open_cell_selected .pi-calendar_hover {
    margin-left: 0px;
    -webkit-transition-duration: .5s;
            transition-duration: .5s;   
}

@media (pointer:fine) {
    .pi-calendar_open_cell:hover .pi-calendar_hover {
        cursor: pointer;
        margin-left: 0px;
        -webkit-transition-duration: .5s;
                transition-duration: .5s;
    }
}

.pi-calendar-left {
    height: 100%;
    left: 0px;
    width: 24px;
    z-index: 1000;
    position: absolute;
    background: -webkit-gradient(linear, left top, right top, from(#eeeeee), to(rgba(238, 238, 238, 0.467)));
    background: linear-gradient(to right, #eeeeee, rgba(238, 238, 238, 0.467));
}

.pi-calendar-right {
    height: 100%;
    right: 0px;
    width: 24px;
    z-index: 1000;
    position: absolute;
    background: -webkit-gradient(linear, right top, left top, from(#eeeeee), to(rgba(238, 238, 238, 0.467)));
    background: linear-gradient(to left, #eeeeee, rgba(238, 238, 238, 0.467));
}

.pi-calendar-spacer {
    height: 100%;
    min-width: 24px;
    width: 24px;
}

@media screen and (min-width: 600px) {
    .pi-calendar_day_card {
        min-width: 248px;
    }
    .pi-calendar-bottom {
        width: 236px;
    }    
    .pi-calendar {
        margin-top: 24px;
        height: calc(100% - 48px);
    }    
}

.ReadOnlyForm {
    padding: 24px;
}

.ReadOnlyForm .ReadOnlyForm_header {
    padding: 0px;
}

.ReadOnlyForm .ReadOnlyForm_row {
    display: flex;
    padding-top: 16px;
    padding-bottom: 8px;
}

.ReadOnlyForm .ReadOnlyForm_row .ReadOnlyForm_cell {
    width: 100%;    
}

.ReadOnlyForm .ReadOnlyForm_row .ReadOnlyForm_cell .ReadOnlyForm_value {
    min-height: 18px;
    width: 100%;    
}

div.ReadOnlyForm div.ReadOnlyForm_actions {
    padding-top: 24px;
}

